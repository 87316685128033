import { useCallback, useState } from "react";
import { readContract, writeContract } from "@wagmi/core";
import useAccount from "@/hooks/useAccount";
import { useSwitchNetwork, useNetwork, useContractReads } from "wagmi";

import { toast } from "react-toastify";
import { waitForTransaction } from "wagmi/actions";
import { parseEther, parseUnits } from "viem";
import { stakingV2ContractInfo, KATContractInfo } from "@/services/contract";
import BigNumber from "bignumber.js";
import { isUserRejectError } from "@/utils/validate";
import events from "@/services/events";
import { isSkipError } from "@/utils/validate";

export default function useStakeV2Contract() {
  const { address, balance, login } = useAccount();
  const { switchNetworkAsync } = useSwitchNetwork();
  const { chain } = useNetwork();
  const [claiming, setClaiming] = useState(false);

  const { data } = useContractReads({
    watch: false,
    scopeKey: "staking-v2-contract",
    contracts: [
      {
        ...stakingV2ContractInfo,
        functionName: "stakingV1Balance",
        args: [address as string],
      },
    ] as any,
  });

  const claim = useCallback(
    async (tokenIds: string[], targetAddress: string) => {
      if (tokenIds.length === 0) {
        return;
      }
      if (!address) {
        return login();
      }
      if (claiming) {
        return;
      }
      setClaiming(true);
      try {
        if (!switchNetworkAsync) {
          return;
        }
        if (chain?.id !== stakingV2ContractInfo.chainId) {
          await switchNetworkAsync(stakingV2ContractInfo.chainId);
        }

        const resp = await writeContract({
          ...stakingV2ContractInfo,
          functionName: "claimStakingV1TokenBatch",
          args: [
            tokenIds.map((id) => {
              return Number(id);
            }),
            targetAddress,
          ],
        });
        try {
          await waitForTransaction({
            hash: resp.hash,
            confirmations: 2,
          });
        } catch (e: any) {
          if (!(await isSkipError(e))) {
            throw e;
          }
        }

        toast.success("Operation successful！");
        return true;
      } catch (e: any) {
        if (isUserRejectError(e)) {
          return false;
        }
        console.error(e);
        toast.error(e.message);
        throw new Error(e.message);
      } finally {
        setClaiming(false);
      }
    },
    [chain, switchNetworkAsync, claiming, address, login]
  );

  console.log("data", data);

  return {
    claim,
    claiming,
  };
}
