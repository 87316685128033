import * as Yup from "yup";

export const validateEmail = (email: string | undefined) => {
  if (!email) {
    return false;
  }
  return Yup.string().email().isValidSync(email);
};

const RTwitterLink = /^https:\/\/twitter.com\/[^/]+\/status\/\d+/;

export const validateTwitterLink = (url: string | undefined) => {
  if (!url) {
    return false;
  }
  return RTwitterLink.test(url);
};

export const validateDiscordServerLink = (url: string | undefined) => {
  if (!url) {
    return false;
  }
  return url.indexOf("https://discord.gg/") === 0;
};

export const validateDiscordChannelLink = (url: string | undefined) => {
  if (!url) {
    return false;
  }
  return url.indexOf("https://discord.com/channels") === 0;
};

const RTelegramGroupLink = /^https:\/\/t.me\/./;

export const validateTelegramGroupLink = (url: string | undefined) => {
  if (!url) {
    return false;
  }
  return RTelegramGroupLink.test(url);
};

const REGEX_ADDRESS = /^0x[A-F0-9]{40}$/i;

export const isValidAddress = (address: string) => REGEX_ADDRESS.test(address);

export const isUserRejectError = (error: Error) => {
  if (error.message.includes("User rejected the request.")) {
    return true;
  }
  return false;
};

export const isSkipError = async (error: Error) => {
  if (error.message.includes("Cannot mix BigInt and other types")) {
    console.log("skip error");
    console.error(error);
    await new Promise((resolve) => {
      setTimeout(resolve, 10000);
    });
    return true;
  }
  return false;
};
