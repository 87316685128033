import { FunctionComponent, useCallback, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { getRoute } from "@/services/config";
import { useRouter } from "next/dist/client/router";
import { Drawer, UnstyledButton, HoverCard, Collapse } from "@mantine/core";
import { MdClose } from "react-icons/md";
import { useDisclosure } from "@mantine/hooks";
import { MenuArrow } from "@/components/common/icons";
import {
  TwitterIcon,
  DiscordIcon,
  MediumIcon,
} from "@/form/components/common/icons";
import store, { useTypedSelector, actions } from "@/stores";
import { CloseIcon } from "@/components/common/icons";
import ConnectWallet from "@/components/common/ConnectWallet";
import V1ToV2 from "../network/staking/V1ToV2";
import { isMaintenance } from "@/config/config";

const DocUrl = "https://docs.karatdao.com";
const BlogUrl = "https://medium.com/@karatdao";

const Nav: FunctionComponent<{
  showConnect?: boolean;
}> = () => {
  const tab = getRoute("dashboard");
  const [drawerOpened, setDrawerOpened] = useState(false);
  const [networkOpened, { toggle: toggleNetwork, close: closeNetwork }] =
    useDisclosure(false);
  const [productOpened, { toggle: toggleProduct, close: closeProduct }] =
    useDisclosure(false);

  const hideMenu = useCallback(() => {
    setDrawerOpened(false);
  }, []);

  const [migrateOpened, { toggle: openMigrate, close: closeMigrate }] =
    useDisclosure(false);

  const announcement = process.env.NEXT_PUBLIC_ANNOUNCEMENT as string;

  const hideAnnouncement = useTypedSelector((state) => {
    return state.app.hideAnnouncement;
  });

  const showAnnouncement = announcement && !hideAnnouncement;

  return (
    <>
      {isMaintenance && (
        <div className="z-40 h-[30px] sticky top-0 left-0 bg-[#5C74DD] text-white flex items-center justify-center text-xs">
          {process.env.NEXT_PUBLIC_MAINTENANCE_MESSAGE}
        </div>
      )}
      {!isMaintenance && showAnnouncement && (
        <div className="z-40 h-[30px] sticky top-0 left-0 bg-[#5C74DD] text-white flex items-center justify-center text-xs">
          {announcement}
          <span className="ml-1">
            <span
              onClick={openMigrate}
              className="underline action font-semibold"
            >
              here
            </span>
            .
          </span>
          <CloseIcon
            onClick={() => {
              store.dispatch(actions.app.hideAnnouncement());
            }}
            className="fill-white w-4 h-4 action absolute right-16"
          ></CloseIcon>
        </div>
      )}

      <div
        className={`z-40 h-[56px] sticky ${
          showAnnouncement ? "top-[30px]" : "top-0"
        }  left-0 backdrop-blur bg-white text-base`}
        style={{
          filter: "drop-shadow(15px 4px 40px rgba(0, 0, 0, 0.1))",
        }}
      >
        <div className="">
          <div className="layout-content flex h-[60px] items-center justify-between sm:justify-start  px-5 md:px-14 relative">
            <Link href={"/"}>
              <Image
                src="/airdrop_assets/karatdao4x.png"
                width={150}
                height={40}
                className="-translate-y-1 action"
              ></Image>
            </Link>
            <div className="max-w-[10] sm:max-w-[150px] flex flex-1"></div>
            <div className="flex-1 flex items-center">
              <HoverCard
                width={321}
                shadow="md"
                offset={2}
                classNames={{
                  dropdown: "bg-dark1 border-none shadow-navCard p-0 py-3",
                }}
              >
                <HoverCard.Target>
                  <div className="hidden mx-5 font-semibold text-primary sm:flex">
                    <div
                      className={`action flex items-center px-4 py-4 hover:text-primary text-primary`}
                    >
                      <span>Network</span>
                      <img
                        className="w-[7px] h-[5px] ml-1"
                        src="/assets/nav-arrow.png"
                      ></img>
                    </div>
                  </div>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <Link href={"/network/mining"}>
                    <div className="py-4 cursor-pointer px-7 group">
                      <div className="font-semibold text-white text-body group-hover:text-tertiary">
                        KAT
                      </div>
                      <div className="text-[#8F8F8F] text-xs mt-1">
                        Multiple ways to earn KAT
                      </div>
                    </div>
                  </Link>
                  <Link href={"/network/mission"}>
                    <div className="py-4 cursor-pointer px-7 group">
                      <div className="font-semibold text-white text-body group-hover:text-tertiary">
                        The mission
                      </div>
                      <div className="text-[#8F8F8F] text-xs mt-1">
                        Join the journey to own your data
                      </div>
                    </div>
                  </Link>

                  <Link href={"/network/claimer"}>
                    <div className="py-4 cursor-pointer px-7 group">
                      <div className="font-semibold text-white text-body group-hover:text-tertiary">
                        Karat ID
                      </div>
                      <div className="text-[#8F8F8F] text-xs mt-1">
                        Verify web2 data and earn
                      </div>
                    </div>
                  </Link>

                  <Link href={"/network/leaderboard"}>
                    <div className="py-4 cursor-pointer px-7 group">
                      <div className="font-semibold text-white text-body group-hover:text-tertiary">
                        Staking
                      </div>
                      <div className="text-[#8F8F8F] text-xs mt-1">
                        Stake and participate in KAT mining
                      </div>
                    </div>
                  </Link>
                </HoverCard.Dropdown>
              </HoverCard>
              <HoverCard
                width={321}
                shadow="md"
                offset={2}
                classNames={{
                  dropdown: "bg-dark1 border-none shadow-navCard p-0 py-3",
                }}
              >
                <HoverCard.Target>
                  <div className="hidden mx-5 font-semibold text-primary sm:flex">
                    <div
                      className={`action flex items-center px-4 py-4 hover:text-primary text-primary`}
                    >
                      <span>Products</span>
                      <img
                        className="w-[7px] h-[5px] ml-1"
                        src="/assets/nav-arrow.png"
                      ></img>
                    </div>
                  </div>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <Link href={"/#form"}>
                    <div className="py-4 cursor-pointer px-7 group">
                      <div className="font-semibold text-white text-body group-hover:text-tertiary">
                        Form
                      </div>
                      <div className="text-[#8F8F8F] text-xs mt-1">
                        Web3 Form to collect wallet addresses
                      </div>
                    </div>
                  </Link>
                  <Link href={"/#analytics"}>
                    <div className="py-4 cursor-pointer px-7 group">
                      <div className="font-semibold text-white text-body group-hover:text-tertiary">
                        Analytics
                      </div>
                      <div className="text-[#8F8F8F] text-xs mt-1">
                        Analyze wallets in batch and get insights
                      </div>
                    </div>
                  </Link>
                  <Link href={"/#airdrops"}>
                    <div className="py-4 cursor-pointer px-7 group">
                      <div className="font-semibold text-white text-body group-hover:text-tertiary">
                        Airdrops
                      </div>
                      <div className="text-[#8F8F8F] text-xs mt-1">
                        Create claimable token and NFT airdrops
                      </div>
                    </div>
                  </Link>
                </HoverCard.Dropdown>
              </HoverCard>
              <Link href={"/airdrops"} passHref>
                <a className="hidden mx-5 font-semibold text-primary sm:flex">
                  <div
                    className={`action flex items-center px-4 py-4 hover:text-primary text-primary`}
                  >
                    <span>Campaigns</span>
                  </div>
                </a>
              </Link>

              <a
                href={DocUrl}
                target="_blank"
                rel="noreferrer"
                className="hidden mx-5 font-semibold text-primary sm:flex"
              >
                <div
                  className={`action flex items-center px-4 py-4 hover:text-primary text-primary`}
                >
                  <span>Doc</span>
                </div>
              </a>
              <a
                href={BlogUrl}
                target="_blank"
                rel="noreferrer"
                className="hidden mx-5 font-semibold text-primary sm:flex"
              >
                <div
                  className={`action flex items-center px-4 py-4 hover:text-primary text-primary`}
                >
                  <span>Blog</span>
                </div>
              </a>
            </div>
            <ConnectWallet></ConnectWallet>
            <>
              <a
                href={tab.route}
                target="_blank"
                rel="noreferrer"
                data-track="nav_enter_app"
                className="ml-6 text-sm"
              >
                <span className="hidden font-semibold action text-primary sm:block">
                  Enter App
                </span>
              </a>
              <div
                className="block action sm:hidden"
                onClick={() => {
                  setDrawerOpened(true);
                }}
              >
                <Image
                  src={"/assets/burger.png"}
                  width={18}
                  height={15}
                ></Image>
              </div>
            </>
          </div>
        </div>
        <Drawer
          opened={drawerOpened}
          onClose={hideMenu}
          padding="xl"
          size="100%"
          position="right"
          trapFocus={false}
          withCloseButton={false}
          classNames={{
            body: "h-full",
          }}
        >
          <UnstyledButton
            className="absolute hover: top-10 right-7 text-primary"
            onClick={hideMenu}
          >
            <MdClose className="w-6 h-6" />
          </UnstyledButton>

          <div className="flex flex-col justify-between mt-4 h-[90%]">
            <div className="flex flex-col items-center justify-center">
              <UnstyledButton
                onClick={() => {
                  toggleNetwork();
                  closeProduct();
                }}
                className="flex items-center flex-initial mt-10 font-bold justify-items-center text-primary action"
              >
                Network
                <MenuArrow
                  className={`ml-1 mt-1 transition-all ${
                    networkOpened ? "" : "rotate-180"
                  }`}
                ></MenuArrow>
              </UnstyledButton>
              <Collapse in={networkOpened}>
                <div className="mt-4 bg-dark1 w-[269px] rounded flex flex-col items-center p-4">
                  <Link href={"/network/mining"}>
                    <div className="text-center action">
                      <div className="text-white font-semibold text-sm">
                        KAT
                      </div>
                      <div className="text-xs text-[#8f8f8f] mt-1">
                        Multiple ways to earn KAT
                      </div>
                    </div>
                  </Link>
                  <Link href={"/network/mission"}>
                    <div className="text-center action mt-4">
                      <div className="text-white font-semibold text-sm">
                        The mission
                      </div>
                      <div className="text-xs text-[#8f8f8f] mt-1">
                        Join the journey to own your data
                      </div>
                    </div>
                  </Link>
                  <Link href={"/network/claimer"}>
                    <div className="text-center action mt-4">
                      <div className="text-white font-semibold text-sm">
                        Karat ID
                      </div>
                      <div className="text-xs text-[#8f8f8f] mt-1">
                        Verify web2 data and earn
                      </div>
                    </div>
                  </Link>
                  {/* <Link href={"/network/validator"}>
                    <div className="text-center action mt-4">
                      <div className="text-white font-semibold text-sm">
                        Validator
                      </div>
                      <div className="text-xs text-[#8f8f8f] mt-1">
                        Pioneer of Karat network. Refer and recruit
                      </div>
                    </div>
                  </Link> */}
                  <Link href={"/network/leaderboard"}>
                    <div className="text-center action mt-4">
                      <div className="text-white font-semibold text-sm">
                        Staking
                      </div>
                      <div className="text-xs text-[#8f8f8f] mt-1">
                        Stake and participate in KAT mining
                      </div>
                    </div>
                  </Link>
                </div>
              </Collapse>
              <UnstyledButton
                onClick={() => {
                  toggleProduct();
                  closeNetwork();
                }}
                className="flex items-center flex-initial mt-10 font-bold justify-items-center text-primary action"
              >
                <div className="flex items-center">
                  Products
                  <MenuArrow
                    className={`ml-1 mt-1 transition-all ${
                      productOpened ? "" : "rotate-180"
                    }`}
                  ></MenuArrow>
                </div>
              </UnstyledButton>

              <Collapse in={productOpened}>
                <div className="mt-4 bg-dark1 w-[269px] rounded flex flex-col items-center p-4">
                  <Link href={"/#form"}>
                    <div className="text-center action" onClick={hideMenu}>
                      <div className="text-white font-semibold text-sm">
                        Form
                      </div>
                      <div className="text-xs text-[#8f8f8f] mt-1">
                        Web3 form to collect wallet addresses
                      </div>
                    </div>
                  </Link>
                  <Link href={"/#analytics"}>
                    <div className="text-center action mt-4" onClick={hideMenu}>
                      <div className="text-white font-semibold text-sm">
                        Analytics
                      </div>
                      <div className="text-xs text-[#8f8f8f] mt-1">
                        Analyze wallets in batch and get insights
                      </div>
                    </div>
                  </Link>
                  <Link href={"/#airdrops"}>
                    <div className="text-center action mt-4" onClick={hideMenu}>
                      <div className="text-white font-semibold text-sm">
                        Airdrops
                      </div>
                      <div className="text-xs text-[#8f8f8f] mt-1">
                        Create claimable tokens and NFT airdrops
                      </div>
                    </div>
                  </Link>
                </div>
              </Collapse>
              <UnstyledButton
                onClick={hideMenu}
                className="flex items-center flex-initial mt-10 font-bold justify-items-center text-primary action"
              >
                <Link href={"/airdrops"} passHref>
                  <a>Campaigns</a>
                </Link>
              </UnstyledButton>
              <UnstyledButton
                onClick={hideMenu}
                className="flex items-center flex-initial mt-10 font-bold justify-items-center text-primary action"
              >
                <a href={DocUrl} target="_blank" rel="noreferrer">
                  Doc
                </a>
              </UnstyledButton>
              <UnstyledButton
                onClick={hideMenu}
                className="flex items-center flex-initial mt-10 font-bold justify-items-center text-primary action"
              >
                <a href={BlogUrl} target="_blank" rel="noreferrer">
                  Blog
                </a>
              </UnstyledButton>
            </div>
            <div className="flex items-center justify-center font-semibold gap-8">
              {/* <a href="">
              <InstagramIcon className="fill-primary h-[18px] w-[20px] action"></InstagramIcon>
            </a> */}
              <a
                href="https://twitter.com/KaratDAO"
                target={"_blank"}
                rel="noreferrer"
              >
                <TwitterIcon className="fill-primary h-[18px] w-[22px] action"></TwitterIcon>
              </a>
              <a
                href="https://discord.gg/fcvFtkfrRA"
                target={"_blank"}
                rel="noreferrer"
              >
                <DiscordIcon className="fill-primary h-4 w-6 action"></DiscordIcon>
              </a>
              <a
                href="https://medium.com/@karatdao"
                target={"_blank"}
                rel="noreferrer"
              >
                <MediumIcon className="fill-primary h-5 w-5 action"></MediumIcon>
              </a>
            </div>
          </div>
        </Drawer>
      </div>
      <V1ToV2 opened={migrateOpened} close={closeMigrate}></V1ToV2>
    </>
  );
};

export default Nav;
