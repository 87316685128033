import { FunctionComponent } from "react";

const Stepper: FunctionComponent<{
  steps: string[];
  active: number;
  width?: number;
  maxLineWidth?: number;
}> = ({ steps, active = 1, width = 80, maxLineWidth = 80 }) => {
  const currentIndex = Math.min(Math.max(1, active), steps.length);

  return (
    <div className="flex w-full items-center justify-center transition-all">
      {steps.map((step, index) => {
        const isFirst = index === 0;
        const isActive = index + 1 <= currentIndex;

        return [
          !isFirst && (
            <div
              className={`mx-3 mt-[14px] h-[2px]  flex-1 self-baseline rounded-[34px] ${
                isActive ? "bg-primary" : "bg-gray2"
              }`}
              key={`split-${index}`}
              style={{
                maxWidth: maxLineWidth,
              }}
            ></div>
          ),
          <div
            className={`relative flex flex-col items-center ${
              isActive ? "opacity-100" : "opacity-60"
            }`}
            key={`step-${index}`}
          >
            <div
              className={`flex h-[30px] w-[30px] items-center justify-center rounded-full font-bold ${
                isActive
                  ? " bg-primary text-white"
                  : "text-gray-500 border-gray-500 border"
              }`}
            >
              {index + 1}
            </div>
            <div
              className={`-left-[calc(50% - 15px)] absolute whitespace-nowrap   -bottom-7 mt-2  text-center text-sm font-normal ${
                isActive ? "text-primary" : "text-gray-500"
              } `}
              style={{
                width,
              }}
            >
              {step}
            </div>
          </div>,
        ];
      })}
    </div>
  );
};

export default Stepper;
