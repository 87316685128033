import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { numberWithCommas } from "@/utils/format";
import { NetworkInfo } from "@/types/network";
import { getNetworkInfo } from "@/services/network";
import BigNumber from "bignumber.js";
import { useDisclosure } from "@mantine/hooks";
import { Modal, Button, TextInput } from "@mantine/core";
import { AiOutlineCloseCircle } from "react-icons/ai";
import useStakeV2Contract from "@/hooks/staking/useStakingV2Contract";
import { V2Validator } from "@/types/network";
import useRequest from "@/hooks/useRequest";
import useAccount from "@/hooks/useAccount";
import Stepper from "@/form/components/demo/analyze/Stepper";
import { trimAddr } from "@/services/format";
import QRCode from "qrcode.react";
import { isValidAddress } from "@/utils/validate";

const V1ToV2: FunctionComponent<{
  opened: boolean;
  close: any;
}> = ({ opened, close }) => {
  const { claim, claiming } = useStakeV2Contract();
  const [step, setStep] = useState(1);
  const [clubAddress, setClubAddress] = useState("");
  const { isLogin, login, address } = useAccount();

  const [v1Balance, setV1Balance] = useState(new BigNumber(0));
  const [bonus, setBonus] = useState(new BigNumber(0));

  useEffect(() => {
    if (!opened) {
      setStep(1);
    }
  }, [opened]);

  const { data: tokenList, mutate } = useRequest<
    {
      id: number;
      amount: string;
    }[]
  >(
    isLogin
      ? {
          function: "chat/getUserV1Tokens",
          data: {},
        }
      : null
  );

  const onClaim = useCallback(async () => {
    await claim(
      (tokenList || []).map((item) => {
        return String(item.id);
      }),
      clubAddress
    );
    await mutate();
  }, [tokenList, clubAddress, mutate]);

  useEffect(() => {
    let total = new BigNumber(0);
    (tokenList || []).forEach((item) => {
      total = total.plus(new BigNumber(item.amount));
    });
    setV1Balance(total);
    setBonus(total.multipliedBy(0.1));
  }, [tokenList]);

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        withCloseButton={false}
        centered
        size={495}
        radius={16}
        zIndex={999}
        classNames={{
          body: "bg-[#F9F9F9]",
        }}
      >
        <div className="pt-5 pb-5 relative px-5 flex flex-col items-center">
          <div className="flex justify-end w-full">
            <AiOutlineCloseCircle
              onClick={close}
              size={26}
              className="fill-primary action "
            ></AiOutlineCloseCircle>
          </div>

          <div className="font-semibold text-xl">
            Migrate V1 staking balance to V2
          </div>
          <div className="whitespace-pre-line mt-7 text-[#2d2d2d] text-sm font-semibold text-center">{`Follow the instructions to migrate your V1 staking balance to new wallet in karat.club App.`}</div>
          {!isLogin ? (
            <div className="flex justify-center mt-4">
              <Button
                size="md"
                radius="xl"
                className="w-[250px]"
                loading={claiming}
                onClick={login}
              >
                Connect wallet
              </Button>
            </div>
          ) : (
            <div className="flex flex-col w-full mt-[30px] pb-[50px]">
              <div className="w-full">
                <Stepper
                  active={step}
                  width={120}
                  maxLineWidth={120}
                  steps={[
                    "V1 Staking total",
                    "Download Karat.club",
                    "Confirmation",
                  ]}
                ></Stepper>
              </div>
              {step === 1 && (
                <div className="mt-14">
                  <div>
                    <div className="text-sm font-semibold">Current Wallet</div>
                    <div className="mt-[6px] rounded-[90px] px-3 py-2 border border-[#d8d8d8] flex  items-center ">
                      <img
                        className="w-4 h-4"
                        src="/assets/zksync-dark-logo.png"
                      ></img>
                      <span className="text-primary text-sm ml-6">
                        {trimAddr(address)}
                      </span>
                    </div>
                    <div className="mt-[30px] rounded-[4px] p-3 border border-[#d8d8d8] text-sm">
                      <div className="flex justify-between items-center">
                        <div>V1 Staking balance:</div>
                        <div>{v1Balance.toFixed(3)} KAT</div>
                      </div>
                      <div className="flex justify-between items-center">
                        <div>10% Loyalty Bonus:</div>
                        <div>{bonus.toFixed(2)} KAT</div>
                      </div>
                      <div className="text-base font-bold flex justify-between items-center">
                        <div>V1 Staking total:</div>
                        <div>{v1Balance.plus(bonus).toFixed(3)} KAT</div>
                      </div>
                    </div>
                    <div className="flex justify-center mt-10">
                      <Button
                        className="w-[200px]"
                        size="lg"
                        radius="xl"
                        onClick={() => {
                          setStep(step + 1);
                        }}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {step === 2 && (
                <div className="mt-14">
                  <div className="text-xs font-semibold">
                    Please scan the QR code to add Karat.club to your mobile
                    desktop, open the app, register your account, copy the
                    wallet address from the app, and proceed with the guided
                    steps upon successful registration.
                  </div>
                  <div className="flex flex-col items-center justify-center mt-6">
                    <div className="p-2 bg-white  rounded-[10px]">
                      <QRCode
                        renderAs="svg"
                        size={80}
                        value={"https://www.karat.club/"}
                      />
                    </div>
                    <div className="text-primary text-sm mt-3">
                      Scan to join
                    </div>
                  </div>
                  <div className="flex justify-center gap-5 mt-10">
                    <div className="relative">
                      <img src="/assets/app-1.png" className="w-[121px]"></img>
                      <div className="w-[22px] h-[22px] rounded-full flex items-center justify-center absolute -top-2 -left-2 bg-primary text-white text-sm font-semibold">
                        1
                      </div>
                    </div>
                    <div className="relative">
                      <img src="/assets/app-2.png" className="w-[121px]"></img>
                      <div className="w-[22px] h-[22px] rounded-full flex items-center justify-center absolute -top-2 -left-2 bg-primary text-white text-sm font-semibold">
                        2
                      </div>
                    </div>
                    <div className="relative">
                      <img src="/assets/app-3.png" className="w-[121px]"></img>
                      <div className="w-[22px] h-[22px] rounded-full flex items-center justify-center absolute -top-2 -left-2 bg-primary text-white text-sm font-semibold">
                        3
                      </div>
                    </div>
                  </div>
                  <div className="mt-6">
                    <TextInput
                      label="Karat.club Wallet"
                      required
                      placeholder="Please enter your wallet address in Karat.club app"
                      classNames={{
                        input: "rounded-[90px] mt-1",
                      }}
                      value={clubAddress}
                      onChange={(e) => {
                        setClubAddress(e.currentTarget.value);
                      }}
                    />
                  </div>
                  <div className="flex justify-center mt-10">
                    <Button
                      className="w-[200px]"
                      size="lg"
                      radius="xl"
                      onClick={() => {
                        if (clubAddress && isValidAddress(clubAddress)) {
                          setStep(step + 1);
                        }
                      }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              )}
              {step === 3 && (
                <div className="mt-14">
                  <div className="text-sm font-semibold">Current Wallet</div>
                  <div className="mt-[6px] rounded-[90px] px-3 py-2 border border-[#d8d8d8] flex  items-center ">
                    <img
                      className="w-4 h-4"
                      src="/assets/zksync-dark-logo.png"
                    ></img>
                    <span className="text-primary text-sm ml-6">
                      {trimAddr(address)}
                    </span>
                  </div>
                  <div className="mt-4 rounded-[4px] p-3 border border-[#d8d8d8] text-sm">
                    <div className="flex justify-between items-center">
                      <div>V1 Staking balance:</div>
                      <div>{v1Balance.toFixed(3)} KAT</div>
                    </div>
                    <div className="flex justify-between items-center">
                      <div>10% Loyalty Bonus:</div>
                      <div>{bonus.toFixed(2)} KAT</div>
                    </div>
                    <div className="text-base font-bold flex justify-between items-center">
                      <div>V1 Staking total:</div>
                      <div>{v1Balance.plus(bonus).toFixed(3)} KAT</div>
                    </div>
                  </div>
                  <div className="flex justify-center mt-6 mb-1">
                    <img src="/assets/export.png" className="w-10"></img>
                  </div>
                  <div className="text-sm font-semibold">Karat.club Wallet</div>
                  <div className="mt-[6px] rounded-[90px] px-3 py-2 border border-[#d8d8d8] flex  items-center ">
                    <img
                      className="w-4 h-4"
                      src="/assets/zksync-dark-logo.png"
                    ></img>
                    <span className="text-primary text-sm ml-6">
                      {trimAddr(clubAddress)}
                    </span>
                  </div>
                  <div className="mt-8 text-center text-sm">
                    <div>Please make sure the wallet address is correct. </div>
                    <div>This is an irreversible step.</div>
                  </div>
                  <div className="flex justify-center mt-5">
                    <Button
                      className="w-[200px]"
                      size="lg"
                      radius="xl"
                      onClick={onClaim}
                      loading={claiming}
                    >
                      Migrate
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default V1ToV2;
